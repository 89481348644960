import Link from 'gatsby-link';
import * as React from 'react';
import { parseCategoryTag } from 'src/services/tags/tags';
import { ITagItemData } from 'src/types/blog.types';
import { Root, TagButton, BlogCategoriesPanelLinksWrapper } from './BlogCategoriesPanel.styled';

interface IProps {
  availableTags: ITagItemData[];
  hiddenCategories?: string[];
  currentCategory?: string;
}

const PARSED_TAGS_ORDER = ['Software craft & AI', 'Data & analytics', 'Telecom', 'Design', 'People', 'News & events'];

const BlogCategoriesPanel = ({ availableTags, currentCategory, hiddenCategories }: IProps) => {
  const createHref = (tagName: string) => {
    const searchParams = new URLSearchParams({ category: tagName });
    return `/latest?${searchParams}`;
  };

  const tags = React.useMemo(() => {
    const filtered = availableTags.filter(({ tagName }) => !hiddenCategories?.includes(tagName));
    const extendedTags = filtered.map(({ tagName }) => ({ raw: tagName, readable: parseCategoryTag(tagName) }));
    const allReadableTagNamesInOrder = extendedTags.reduce(
      (acc, v) => {
        if (acc.every(av => av !== v.readable)) {
          acc.push(v.readable);
        }

        return acc;
      },
      [...PARSED_TAGS_ORDER] as string[],
    );

    const allTags = allReadableTagNamesInOrder.flatMap(t => extendedTags.filter(et => et.readable === t));

    return allTags;
  }, [availableTags, hiddenCategories]);

  return (
    <Root>
      <BlogCategoriesPanelLinksWrapper>
        <Link to="/latest">
          <TagButton $isHighlighted={!currentCategory}>All</TagButton>
        </Link>
        {tags.map(({ raw, readable }, index: number) => (
          <Link key={index} to={createHref(raw)} state={{ keepScrollPosition: true }}>
            <TagButton $isHighlighted={currentCategory === raw}>{parseCategoryTag(readable)}</TagButton>
          </Link>
        ))}
      </BlogCategoriesPanelLinksWrapper>
    </Root>
  );
};

export default BlogCategoriesPanel;
